<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-title>
						<v-spacer />
						<Button
								type="submit"
								@click="newContent"
						>
							<v-icon>mdi-plus</v-icon>
						</Button>
					</v-card-title>

					<v-card-text>
						<v-data-table
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								item-key="id"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:[`item.action`]="{ item }">
								<v-icon
										small
										class="mr-2"
										@click="editContent(item)"
								>
									mdi-pencil
								</v-icon>
								<v-icon
										small
										class="mr-2"
										@click="deleteItem(item)"
								>
									mdi-delete
								</v-icon>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog
				v-model="edit.dialog"
				max-width="600px"
				@keyup.esc="cancel"
		>
			<v-card>
				<v-card-title>
					Flag
					<v-spacer />
					<Button icon text @click="cancel">
						<v-icon>mdi-close</v-icon>
					</Button>
				</v-card-title>
				<v-divider />
				<v-card-text style="height: 300px;">
					<v-form>
						<v-col>
							<v-text-field
									v-model="edit.form.title"
									label="Title"
									value=""
									counter
									maxlength="50"
									hide-details
							/>
						</v-col>
						<v-col>
							<v-text-field
									v-model="edit.form.description"
									label="Description"
									value=""
									counter
									maxlength="50"
									hide-details
							/>
						</v-col>
						<v-col>
							<v-row>
								<v-col>
									<v-menu
											ref="datemenu"
											v-model="date.menu"
											:close-on-content-click="false"
											:return-value.sync="date.date"
											transition="scale-transition"
											offset-y
											min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
													v-model="date.date"
													readonly
													v-on="on"
											/>
										</template>
										<v-date-picker v-model="date.date" no-title scrollable>
											<v-spacer/>
											<Button text @click="date.menu = false">Cancel</Button>
											<Button text type="submit" @click="$refs.datemenu.save(date.date)">OK</Button>
										</v-date-picker>
									</v-menu>
								</v-col>
							</v-row>
						</v-col>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-spacer/>
					<Button type="submit" @click="saveContent">Save</Button>
				</v-card-actions>
			</v-card>
		</v-dialog>


	</v-container>

</template>

<script>
	import { globalMixIn } from '@/App.vue'
	import moment from 'moment'

	export default {
		data: () => ({
			headers: [
				{ text: 'Title', value: 'title' },
				{ text: 'Description', value: 'description' },
				{ text: 'Date', value: 'date' },
				{ text: 'Actions', value: 'action', align: 'center', sortable: false },
			],
			date: {
				date: new Date().toISOString().substr(0, 10),
				menu: false,
				modal: false,
				menu2: false,
			},
			totalItems: 0,
			items: [],
			edit: {
				dialog: false,
				form: {
					title: null,
					description: null,
				},
			},
		}),
		methods: {
			cancel() {
				this.edit.dialog = false
			},
			refreshContent () {
				this.$httpInt.get(`/v2/statistics/flags`)
					.then(res => {
						this.items = res.data
						this.totalItems = res.meta.total
					})
			},
			editContent (item) {
				this.edit.dialog    = true
				this.edit.form      = item
				this.date.date      = moment(item.date).isValid() ? moment(item.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
			},
			deleteItem (item) {
				this.$httpInt.delete(`/v2/statistics/flags/${item.id}`)
					.then(() => this.refreshContent())
			},
			newContent () {
				this.edit.dialog = true
				this.edit.form = {}
			},
			saveContent () {
				this.edit.form.date = moment(this.date.date).utc().format("YYYY-MM-DD")

				if (this.edit.form.id) {
					this.$httpInt.patch(`/v2/statistics/flags`, this.edit.form)
						.then(() => {
							this.edit.dialog = false
							this.refreshContent()
						})
				} else {
					this.$httpInt.post(`/v2/statistics/flags`, this.edit.form)
						.then(() => {
							this.edit.dialog = false
							this.refreshContent()
						})
				}
			},

		},
		mixins: [globalMixIn],
		mounted () {
			this.refreshContent()
		},
		name: `StatisticsFlags`,
	}
</script>

<style>
	.v-time-picker-clock {
		width: 270px !important;
	}
</style>